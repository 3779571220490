import * as React from "react";
import styled from "styled-components";
import Layout from "../components/layout";

const StyledMainContainer = styled.main`
  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
  }
`;

// markup
const NotFoundPage = ({ location }) => {
  return (
    <Layout location={location}>
      <StyledMainContainer>
        <title>Not found</title>
        <h1>
          Great Scott! You just hit{" "}
          <span style={{ textDecoration: "line-through" }}>88mph</span> 404!
        </h1>

        <p>
          You may have just caused a chain reaction that could unravel the very
          fabric of the space-time continuum and destroy the entire universe!
          <br />
          Granted, that's the worst-case scenario.
          <br />
          <a className="email-link" href="/">
            Repair the space-time continuum
          </a>
        </p>
      </StyledMainContainer>
    </Layout>
  );
};

export default NotFoundPage;
